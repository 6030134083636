import React from "react";
import Cookies from "js-cookie";
import { FaTimes } from "react-icons/fa";

export const OutOfStock = ({ data, setData }) => {
  const downloadCSV = (e) => {
    e.preventDefault();
    const csv = data.map((row) => Object.values(row).join(",")).join("\n");
    const csvFile = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvFile);
    const a = document.createElement("a");
    a.href = url;
    const today = new Date().toISOString().split("T")[0];
    a.download = `${today} Out of Stock.csv`;
    a.click();
  };

  const updateWoocommerce = async (e) => {
    e.preventDefault();
    const ids = data.map((row) => row.id);

    const chunkArray = (array, chunkSize) => {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
    };

    const idChunks = chunkArray(ids, 200);

    const allPromises = idChunks.map((chunk) => {
      return fetch(`api/stock/update-product`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": Cookies.get("auth-token"),
        },
        body: JSON.stringify({ ids: chunk }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (!json.success) {
            throw new Error(`Error updating products: ${json.error}`);
          }
          return json;
        })
        .catch((error) => {
          console.error("Error updating products:", error);
          throw error;
        });
    });

    try {
      await Promise.all(allPromises);
      alert("All products updated successfully");
    } catch (error) {
      console.error("One or more requests failed:", error);
    }
  };

  return (
    <div className="delete-confirmation edit-order-report">
      <div
        className="delete-confirmation-dialog  edit-order-report-dialoge"
        style={{ width: "750px" }}
      >
        <form className="addrecord-form">
          <div
            className="d-flex justify-content-end pt-3"
            style={{ cursor: "pointer" }}
            onClick={() => setData([])}
          >
            <FaTimes style={{ fontSize: "24px", fontWeight: "10" }} />
          </div>
          <div className="product-list-pc invoice-details-container ">
            <div className="scroll-container-pc">
              <table className="w-100">
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>ID</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((product, index) => (
                    <tr key={index}>
                      <td style={{ minWidth: "150px", maxWidth: "300px" }}>
                        {product.SKU}
                      </td>
                      <td>{product.id}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="buttons edit-order-save-btns d-flex justify-content-center align-items-center">
            <button
              className="btn btn-outline-success edit-order-save-btn w-10"
              onClick={downloadCSV}
            >
              Download
            </button>
            <button
              className="btn btn-outline-success edit-order-save-btn"
              onClick={updateWoocommerce}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
