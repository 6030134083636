import React, { useState } from "react";
import * as xlsx from "xlsx";
import Cookies from "js-cookie";
import { OutOfStock } from "./OutOfStock";
import { Loader } from "./Loader";

export default function StockReport(props) {
  const [fields, setFields] = useState([]);
  const [dealerFile, setDealerFile] = useState();
  const [WooCommerceFile, setWoocommerceFile] = useState();
  const [selectedSKUField, setSelectedSKUField] = useState("");
  const [selectedStockField, setSelectedStockField] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState();
  const [outOfStock, setOutOfStock] = useState([]);
  const genHeaders = async (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = xlsx.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = xlsx.utils.sheet_to_json(worksheet, { header: 1 });
      const headers = jsonData[0];

      setFields(headers || []);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = async (e, type) => {
    e.preventDefault();
    const formData = new FormData();
    setLoading(true);
    if (type === "DEALER")
      formData.append("file", dealerFile, "dealerStock.xlsx");
    if (type === "WOOCOMMERCE")
      formData.append("file", WooCommerceFile, "woocommerceStock.csv");

    const response = await fetch(
      `api/stock/upload?fileType=${type}&skuColumnName=${selectedSKUField}&stockColumnName=${selectedStockField}`,
      {
        method: "POST",
        body: formData,
      }
    );
    setLoading(false);
    const json = await response.json();
    if (json.success) {
      props.showAlert(json.message, 3000);
    } else {
      props.showAlert(json.error, 3000);
    }
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      if (type === "DEALER") {
        setDealerFile(file);
        genHeaders(file);
      }
      if (type === "WOOCOMMERCE") {
        setWoocommerceFile(file);
      }
    }
  };

  const genReport = async () => {
    setLoading(true);
    const response = await fetch(
      `api/stock/generate-report?filter=${filterType}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": Cookies.get("auth-token"),
        },
      }
    );
    const json = await response.json();
    setLoading(false);
    if (json.success && filterType !== "OUT_OF_STOCK") {
      const csvData = json.report
        .map((row) => Object.values(row).join(","))
        .join("\n");
      const csvHeader = Object.keys(json.report[0]).join(",") + "\n";
      const csvContent = csvHeader + csvData;

      const blob = new Blob([csvContent], { type: "text/csv" });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const today = new Date().toISOString().split("T")[0];
      a.href = url;
      a.download = `${today} ${
        filterType === "IN_STOCK"
          ? "In Stock"
          : filterType === "BOTH"
          ? ""
          : "Newly Added"
      }.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      props.showAlert(json.message, 3000);
    } else if (json.success && filterType === "OUT_OF_STOCK") {
      setOutOfStock(json.report);
    } else {
      props.showAlert(json.error, 3000);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="d-flex flex-column gap-2  w-100">
      <div className="container">
        <div className="row">
          <div className="col-5 col-md-6 col-lg-3 ">
            <div className="search-dates pb-4">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      WooCommerce
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                    style={{ minWidth: "100px" }}
                  >
                    <div className="accordion-body">
                      <input
                        className="date-input"
                        onChange={(e) => handleFileChange(e, "WOOCOMMERCE")}
                        name="fileupload"
                        type="file"
                        required
                      />
                      <br />
                      <button
                        type="submit"
                        className="btn btn-outline-success search-btn"
                        onClick={(e) => handleSubmit(e, "WOOCOMMERCE")}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="responsive-select d-flex justify-content-start gap-4 ">
        <input
          onChange={(e) => handleFileChange(e, "DEALER")}
          name="fileupload"
          type="file"
          required
        />{" "}
        <br />
        {fields.length > 0 && (
          <select
            name="sku"
            value={selectedSKUField}
            onChange={(e) => setSelectedSKUField(e.target.value)}
            required
            style={{ minWidth: "200px", maxWidth: "300px" }}
          >
            <option value="">Select SKU</option>
            {fields.map((field, index) => (
              <option key={index} value={field}>
                {field}
              </option>
            ))}
          </select>
        )}
        {fields.length > 0 && (
          <select
            name="stock"
            value={selectedStockField}
            onChange={(e) => setSelectedStockField(e.target.value)}
            required
            style={{ minWidth: "200px", maxWidth: "300px" }}
          >
            <option value="">Select STOCK</option>
            {fields.map((field, index) => (
              <option key={index} value={field}>
                {field}
              </option>
            ))}
          </select>
        )}
      </div>

      {fields.length > 0 && (
        <button
          type="submit"
          className="btn btn-outline-success search-btn w-25"
          onClick={(e) => handleSubmit(e, "DEALER")}
          disabled={!selectedSKUField || !selectedStockField}
        >
          Upload
        </button>
      )}
      <div className="container">
        <div className="row d-flex align-items-center gap-2 mt-2">
          <div className="col-12 col-lg-auto d-flex gap-2">
            <input
              type="radio"
              id="inStock"
              name="stockStatus"
              value="IN_STOCK"
              onClick={(e) => setFilterType(e.target.value)}
            />
            <label htmlFor="inStock">In Stock</label>
          </div>
          <div className="col-12 col-lg-auto  d-flex gap-2">
            <input
              type="radio"
              id="outStock"
              name="stockStatus"
              value="OUT_OF_STOCK"
              onClick={(e) => setFilterType(e.target.value)}
            />
            <label htmlFor="outStock">Out Stock</label>
          </div>
          <div className="col-12 col-lg-auto  d-flex gap-2">
            <input
              type="radio"
              id="both"
              name="stockStatus"
              value="BOTH"
              onClick={(e) => setFilterType(e.target.value)}
            />
            <label htmlFor="both">BOTH</label>
          </div>
          <div className="col-12 col-lg-auto  d-flex gap-2">
            <input
              type="radio"
              id="newlyAdded"
              name="stockStatus"
              value="NEWLY_ADDED"
              onClick={(e) => setFilterType(e.target.value)}
            />
            <label htmlFor="newlyAdded">Newly Added</label>
          </div>
        </div>
      </div>
      <button
        className="btn btn-outline-success search-btn w-25 "
        onClick={genReport}
        disabled={!filterType}
        style={{ minWidth: "200px" }}
      >
        Generate Report
      </button>
      {outOfStock.length > 0 && (
        <OutOfStock data={outOfStock} setData={setOutOfStock} />
      )}
    </div>
  );
}
