import React from 'react';
export function Loader(props) {
    return (
        <>
            <div className='loader'>
                <div class="dot white"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </>
    )
}


